import React  from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Form as BootstrapForm, Button, InputGroup, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setStream, setLocation } from '../../Redux/Slices/JobFiltersSlices';
import { FetchJobsList } from '../../Redux/Slices/JobListApi';
import CustomAsyncSelectField from './SelectComponents';
import DesignationSelectField from './designationSuggetion';



const validationSchema = Yup.object({
    what: Yup.string(),
    where: Yup.string()
});


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff !important',
        borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
        '&:hover': {
            borderColor: '#D2C9FF',
        },
        // maxWidth: '250px',
        width: '250px',
        height: '44px',
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0'
    }),
    menu: (provided) => ({
        ...provided,
        borderTop: '1px solid #D2C9FF',
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #D2C9FF',
        color: state.isSelected ? '#fff' : '#000000',
        backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
        '&:hover': {
            backgroundColor: '#80CBC4',
            color: '#fff',
        },
    }),
};

const JobSearchForm = () => {
    const ActionDispatch = useDispatch();

    return (
        <Formik
            initialValues={{ what: '', where: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                console.log("Form submitted with values:", values);
                ActionDispatch(setStream(values.what))
                ActionDispatch(setLocation(values.where))
                ActionDispatch(FetchJobsList({ stream: values.what, location: values?.where , page_no:'1' , job_type:'' , salary_range:''}))
                setSubmitting(false);
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <BootstrapForm noValidate onSubmit={handleSubmit}>
                    <BootstrapForm.Group as={Col} controlId="formGridAddress1">
                        <div className="selectflex">
                            <InputGroup>
                                <InputGroup.Text>What</InputGroup.Text>
                                <Field
                                    name="what"
                                    component={DesignationSelectField}
                                    customStyles={customStyles}
                                >
                                </Field>
                                {/* <ErrorMessage name="what" component="div" className="text-danger" /> */}
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text id="inputGroup-sizing-lg">Where</InputGroup.Text>
                                <div style={{width:'250px'}}><Field
                                    name="where"
                                    component={CustomAsyncSelectField}
                                    customStyles={customStyles}
                                /></div>
                                {/* <ErrorMessage name="where" component="div" className="text-danger" /> */}
                            </InputGroup>
                            <Button className="sitebtn jobsearch bgblue" type="submit" disabled={isSubmitting}>
                                Find Jobs
                            </Button>
                        </div>
                    </BootstrapForm.Group>
                </BootstrapForm>
            )}
        </Formik>
    );
};

export default JobSearchForm;
