import { createSlice } from "@reduxjs/toolkit";

// create the stream and Location 
const initialState = {
     selectedStream: '',
     selectedLocation:'',  
}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers:{
        setStream: (state, action) => {
            state.selectedStream = action.payload
        },
        setLocation: (state, action) => {
            state.selectedLocation = action.payload
        }
    }
})

export const { setStream , setLocation }  = filterSlice.actions;
export default filterSlice.reducer;

