import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import JobListing from "./job-portal/JobListing";
import JobDetails from "./job-portal/JobDetails";
import ApplyNow from "./job-portal/Applynow";
import JobNavbar from "./job-portal/JobNav";


function App() {
  return (
    <>
      <BrowserRouter>
        <JobNavbar />
        <Routes>
          <Route path="/" element={<JobListing />} />
          <Route path="/job-listing" element={<JobListing />} />
          <Route path="/job-details/:id" element={<JobDetails />} />
          <Route path="/apply/:id" element={<ApplyNow />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
