import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";

function JobCards({jobs}) {
    return (
        <>
           
                <div className="card jobcard" key={jobs?._id}>
                    <div className="card-body">
                        <div className="dflexbtwn">
                            <span className="date"><FiCalendar /> {jobs?.add_date}</span>
                            <span className="type">{jobs?.job_type}</span>
                        </div>
                        <h5 className="job_profile">{jobs?.job_title}</h5>
                        <div className="d-flex compny_site">
                            <p>{jobs?.project_name}</p>
                            <span className="work_loc">{jobs?.working}</span>
                        </div>
                        <div className="dflexbtwn">
                            <div className="salry">
                                <span>Salary Range</span>
                                <p><MdOutlineCurrencyRupee />{jobs?.salary_range}</p>
                            </div>
                            <div className="location">
                                <span>Location</span>
                                <p><IoLocationOutline /> {jobs?.location[0]?.name?.split(',').slice(0 , 3).join(',')+"...."} </p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}
export default JobCards;