import React, { useState, useEffect } from "react";
import JobCards from "./JobCards";
import TopBanner from "./TopBanner";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { InfinitySpin } from 'react-loader-spinner'
import notFoundImage from '../images/notFound.png'
import connectionError from '../images/ConnectionError.jpg';
import { useDispatch } from "react-redux";
import { FetchJobsList } from "../Redux/Slices/JobListApi";

function Job_listing() {
    const [currentPage, setCurrentPage] = useState(9);
    const ActionDispatch = useDispatch();

    useEffect(() => {
        ActionDispatch(FetchJobsList({ stream: '', location: '', par_Page_record: `${currentPage}` }))
    }, [ActionDispatch, currentPage])

    const selectedStream = useSelector((state) => state.filter.selectedStream)
    const Jobs = useSelector(state => state.jobs.jobsList);
    return (
        <>
            <TopBanner />

            <div className="container">
                <div className="joblist_display mt-4">
                    <div className="jobcounts">
                        <h5><span>{Jobs.data.length !== 0 ? Jobs.data.data.length : 0}</span> Jobs Openings In {selectedStream === '' ? 'All' : selectedStream} Stream</h5>
                    </div>
                    <div className="row">
                        {
                            Jobs.status === 'loading' ?
                                <div className="d-flex align-items-center justify-content-center" role="status">
                                    <InfinitySpin
                                        visible={true}
                                        width="400"
                                        color="#30A9E2"
                                        ariaLabel="infinity-spin-loading"
                                    />
                                </div> :
                                Jobs.status === 'failed' ? <div className="d-flex align-items-center justify-content-center">
                                    <img className="" style={{ maxWidth: '200px', maxHeight: '200px' }} src={connectionError} alt="data not Found" />
                                </div> :
                                    Jobs.data.length === 0 ? <div className="d-flex align-items-center justify-content-center">
                                        <img className="" style={{ maxWidth: '200px', maxHeight: '200px' }} src={notFoundImage} alt="data not Found" />
                                    </div> :
                                        Jobs.data.data.map((job) => {
                                            return (
                                                <>
                                                    <div className="col-sm-4" key={job._id}>
                                                        <Link to={`/job-details/${job._id}`} key={job._id}>
                                                            <JobCards jobs={job} />
                                                        </Link>
                                                    </div>
                                                </>
                                            )
                                        })
                        }
                        {/* adding the PageNation with Dynamic PageName */}
                        {
                          Jobs.data.length !== 0 && Jobs.data.data.length >= currentPage &&
                            <div className="pagination">
                                <button
                                    className="page-button"
                                    onClick={() => setCurrentPage(currentPage + 6)}
                                >
                                    View More
                                </button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    );
}
export default Job_listing;