import moment from 'moment';


export const DateConverts = (date) => {
    if(!date){
        return '';
    }
    return moment(date).format('D MMMM YYYY');
}

export const camelCaseHandle = (str) => {
    if (!str) {
        return '';
    }
    return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (match, char) => char.toUpperCase());
};
