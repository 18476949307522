const apiHeaderToken = (token) => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'hrms_secret_key': `${token}`
        }
    }
}

const apiHeaderTokenMultiPart = (token) => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'hrms_secret_key': `${token}`
        }
    }
}


export { apiHeaderToken, apiHeaderTokenMultiPart };


